/* INFO BAR */
.info-bar {
	background-color: #000;
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
	.info {
		float: left;
		margin-right: 90px;
	}
	p {
		color: #fff;
		font-size: 14px;
		position: relative;
		padding-left: 50px;
		margin: 16px 0;
	}
	i {
		font-size: 22px;
		color: #fff;
		position: absolute;
		left: 0;
		top: -4px;
	}
	@media(max-width: 768px) {
		display: none;
	}
}

/* NAVBAR */
.navbar {
	background-color: transparent;
	padding: 10px;
	position: fixed;
	z-index: 999;
	width: 100%;
	top: 48px;
	transition: all .2s;
	.logo {
		font-family: $mainfont;
		font-size: 22px;
		color: #fff;
		text-transform: uppercase;
		letter-spacing: 2.5px;
		display: inline-block;
		span {
			font-family: $bodyfont;
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 2.5px;
			display: block;
			text-align: center;
		}
		@media(max-width: 768px) {
			position: absolute;
			top: -40px;
			color: #000;
		}
	}
	.main-menu {
		float: right;
		font-family: $bodyfont;
		margin-top: 10px;
		> li {
			float: left;
			margin-left: 40px;
			&:first-child {
				margin-left: 0;
			}
			a {
				color: #fff;
				font-size: 16px;
				&:hover {
					color: $browncolor;
				}
			}
			@media(max-width: 980px) {
				margin-left: 20px;
			}
			@media(max-width: 840px) {
				margin-left: 14px;
			}
		}
		@media(max-width: 768px) {
			display: none;
		}
	}
}

/* SLIDER */
.slider {
	overflow: hidden;
	li {
		position: relative;
		img {
			width: 100%;
		}
	}
	.content-wraper {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.5);
		top: 0;
		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;
			p.small-text {
				font-size: 30px;
				color: #fff;
				font-family: $bodyfont;
				text-transform: uppercase;
				letter-spacing: 4px;
				@media(max-width: 780px) {
					font-size: 20px;
				}
				@media(max-width: 470px) {
					font-size: 16px;
				}
				@media(max-width: 370px) {
					font-size: 12px;
				}
			}
			p.big-text {
				font-size: 60px;
				color: #fff;
				font-family: $mainfont;
				text-transform: uppercase;
				margin: 18px 0;
				letter-spacing: 8px;
				@media(max-width: 930px) {
					font-size: 50px;
				}
				@media(max-width: 840px) {
					font-size: 40px;
				}
				@media(max-width: 780px) {
					font-size: 30px;
				}
				@media(max-width: 470px) {
					font-size: 20px;
				}
				@media(max-width: 370px) {
					font-size: 14px;
				}
			}
		}
	}
	@media(max-width: 768px) {
		margin-top: 49px;
	}
}

/* OUR STORY */
.our-story {
	padding: 100px 0;
	background-color: #fff;
	.section-text {
		margin-bottom: 100px;
		.body-text {
			font-style: italic;
			text-align: center;
			line-height: 20px;
			max-width: 800px;
			margin: auto;
		}
		@media(max-width: 768px) {
			margin-bottom: 30px;
		}
	}
	.icon img {
		margin: auto;
		display: block;
		margin-bottom: 24px;
		margin-top: 34px;
	}
	.section-title.story {
		font-size: 30px;
		margin-bottom: 34px;
		letter-spacing: 1px;
		@media(max-width: 768px) {
			margin-bottom: 20px;
		}
	}
	.body-text.story {
		font-style: normal;
		line-height: 20px;
		@media(max-width: 768px) {
			margin-bottom: 40px;
		}
	}
	img.story-img {
		margin: auto;
		display: block;
	}
	@media(max-width: 520px) {
		padding: 50px 0;
	}
}

/* RESERVATION SECTION */
.reservation-section {
	background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),
				url("../images/reserv-img.jpg") no-repeat;
	background-attachment: fixed;
	background-size: cover;
	max-height: 100%;
	padding: 70px 0 40px;
	p.title {
		color: #fff;
		text-align: center;
		text-transform: uppercase;
		font-size: 24px;
		letter-spacing: 2.5px;
		margin-bottom: 6px;
	}
	.section-title {
		color: #fff;
		margin-bottom: 60px;
	}
	.body-text {
		color: #fff;
		margin-bottom: 6px;
	}
	input {
		width: 100%;
		height: 40px;
		outline: none;
		background-color: transparent;
		border: 1px solid #fff;
		font-size: 16px;
	    font-family: $bodyfont;
	    color: #fff;
	    padding-left: 6px;
	    margin-bottom: 36px;
	    &:hover {
	    	background-color: #fff;
	    	color: #000;
	    }
	    &:focus {
	    	background-color: #fff;
	    	color: #000;
	    }
	}
	/* PLACEHOLDER STYLES */
	::-webkit-input-placeholder {
	   color: #fff;
	   font-size: 16px;
	   font-family: $bodyfont;
	}
	:-moz-placeholder {
	   color: #fff;
	   font-size: 16px;
	   font-family: $bodyfont;
	}
	::-moz-placeholder {
	   color: #fff;
	   font-size: 16px;
	   font-family: $bodyfont; 
	}
	:-ms-input-placeholder {  
	   color: #fff;
	   font-size: 16px;
	   font-family: $bodyfont;  
	}
	input[type=submit] {
		text-transform: uppercase;
	}
}

/* SPECIALS SECTION */
.specials-section {
	padding: 100px 0;
	background-color: #fff;
	.icon img {
		margin: auto;
		display: block;
		margin-bottom: 24px;
	}
	.section-title {
		margin-bottom: 70px;
	}
	.img-wrap {
		position: relative;
		max-width: 270px;
		&:hover .img-hover {
			opacity: 0;
			transition: all .4s;
		}
		.img-hover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.6);
			.caption-text {
				position: absolute;
				bottom: 20px;
				color: #fff;
				width: 100%;
				.section-title {
					margin-bottom: 0;
					line-height: 40px;
				}
				p {
					font-size: 22px;
					text-transform: uppercase;
					text-align: center;
					letter-spacing: 3px;
					margin-bottom: 10px;
				}
				i {
					font-size: 50px;
					display: block;
					text-align: center;
				}
				@media(max-width: 1050px) {
					.section-title {
						font-size: 24px;
					}
				}
				@media(max-width: 870px) {
					.section-title {
						font-size: 18px;
					}
					p {
						font-size: 16px;
					}
				}
				@media(max-width: 768px) {
					.section-title {
						font-size: 30px;
					}
					p {
						font-size: 24px;
					}
				}
				@media(max-width: 260px) {
					.section-title {
						font-size: 20px;
					}
					p {
						font-size: 18px;
					}
				}
			}
		}
		@media(max-width: 768px) {
			margin: auto;
			margin-bottom: 30px;
		}
	}
}

/* OUR MENU SECTION */
.menu-section {
	padding: 0 0 80px;
	background-color: #fff;
	.icon img {
		margin: auto;
		display: block;
		margin-bottom: 24px;
	}
	.section-title {
		margin-bottom: 70px;
	}
	h3.title {
		font-size: 30px;
		color: $browncolor;
		font-family: $mainfont;
		text-align: center;
		margin-bottom: 30px;
	}
	.meal-info {
		position: relative;
		p.name {
			font-size: 17px;
			color: #000;
			font-weight: 700;
			margin-bottom: 7px;
		}
		.body-text {
			margin-bottom: 20px;
		}
		.price {
			font-size: 30px;
			color: #000;
			font-family: $mainfont;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

/* TESTIMONIALS / WHAT PEOPLE SAY */
.testimonials-section {
	background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),
				url("../images/testimonials-img.jpg") no-repeat;
	background-attachment: fixed;
	background-size: cover;
	max-height: 100%;
	padding: 70px 0 60px;
	i {
		text-align: center;
		display: block;
		margin-bottom: 20px;
		color: #fff;
		font-size: 54px;
	}
	.section-title {
		color: #fff;
		font-size: 30px;
		margin-bottom: 40px;
	}
	.flexslider2 {
		max-width: 800px;
		display: block;
		margin: auto;
		color: #fff;
		font-size: 17px;
		.testimonial-txt {
			font-style: italic;
			margin-bottom: 10px;
		}
		.client {
			font-size: 17px;
			color: #fff;
			text-align: right;
			text-transform: uppercase;
			margin-bottom: 34px;
		}
		.flex-control-paging li a {
			width: 20px;
			height: 20px;
			background-color: transparent;
			border: 2px solid #fff;
			&:hover {
				background-color: #fff;	
			}
		}
		.flex-control-paging .flex-active {
			background-color: #fff;
		}
	}
}

/* GALLERY SECTION */
.gallery-section {
	padding: 100px 0 70px;
	background-color: #fff;
	.icon img {
		margin: auto;
		display: block;
		margin-bottom: 24px;
	}
	.section-title {
		margin-bottom: 70px;
	}
	.img-wrap {
		position: relative;
		max-width: 370px;
		display: block;
		margin-bottom: 30px;
		outline: none;
		&:hover .img-hover {
			background-color: rgba(255,255,255,.3);
			transition: all .4s;
		}
		.img-hover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255,0);
		}
		@media(max-width: 768px) {
			margin: 0 auto 30px;
		}
	}
}

/* OUR CLIENTS SECTION */
.clients-section {
	background: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)),
				url("../images/clients-bg-img.jpg") no-repeat;
	background-attachment: fixed;
	background-size: cover;
	max-height: 100%;
	padding: 70px 0;
	i {
		text-align: center;
		display: block;
		margin-bottom: 20px;
		color: #fff;
		font-size: 54px;
	}
	.section-title {
		color: #fff;
		font-size: 30px;
		margin-bottom: 70px;
	}
	@media(max-width: 768px) {
		.col-1-5 {
			width: 50%;
			img {
				margin: auto;
				display: block;
				margin-bottom: 20px;
			}
		}
	}
	@media(max-width: 480px) {
		.col-1-5 {
			width: 100%;
		}
	}
}

/* CONTACT US */
.contact-section {
	padding: 80px 0 70px;
	background-color: #fff;
	.icon img {
		margin: auto;
		display: block;
		margin-bottom: 24px;
	}
	.section-title {
		margin-bottom: 70px;
	}
	.body-text {
		margin-bottom: 6px;
	}
	.container-sm {
		max-width: 80%;
		margin: auto;
	}
	input {
		width: 100%;
		height: 40px;
		outline: none;
		background-color: transparent;
		border: 1px solid #000;
		font-size: 16px;
	    font-family: $bodyfont;
	    color: #000;
	    padding-left: 6px;
	    margin-bottom: 36px;
	    &:hover {
	    	border: 1px solid $browncolor;
	    }
	    &:focus {
	    	border: 1px solid $browncolor;
	    }
	}
	/* PLACEHOLDER STYLES */
	::-webkit-input-placeholder {
	   color: #000;
	   font-size: 16px;
	   font-family: $bodyfont;
	}
	:-moz-placeholder {
	   color: #000;
	   font-size: 16px;
	   font-family: $bodyfont;
	}
	::-moz-placeholder {
	   color: #000;
	   font-size: 16px;
	   font-family: $bodyfont; 
	}
	:-ms-input-placeholder {  
	   color: #000;
	   font-size: 16px;
	   font-family: $bodyfont;  
	}
	input[type=submit] {
		text-transform: uppercase;
	}
	textarea {
		width: 100%;
		height: 110px;
		outline: none;
		background-color: transparent;
		border: 1px solid #000;
		font-size: 16px;
	    font-family: $bodyfont;
	    color: #000;
	    padding-left: 6px;
	    padding-top: 8px;
	    margin-bottom: 36px;
	    &:hover {
	    	border: 1px solid $browncolor;
	    }
	    &:focus {
	    	border: 1px solid $browncolor;
	    }
	}
	input[type=submit] {
		width: 100%;
		height: 40px;
		outline: none;
		background-color: transparent;
		border: 1px solid $browncolor;
		font-size: 16px;
	    font-family: $bodyfont;
	    color: $browncolor;
	    &:hover {
	    	background-color: $browncolor;
	    	color: #fff;
	    }
	}
}


/* FOOTER */
footer {
	padding: 50px 0;
	background-color: #000;
	color: #fff;
	text-align: center;
	h3.title {
		font-size: 25px;
		font-family: $mainfont;
		color: $browncolor;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
	.footer-copyright {
		margin-top: 70px;
		.logo {
			h3.title {
				margin-bottom: 0;
				font-size: 20px;
				color: #fff;
				letter-spacing: 3px;
			}
			.body-text {
				text-transform: uppercase;
				letter-spacing: 3px;
				font-size: 14px;
			}
		}
		.body-text {
			text-transform: uppercase;
		}
		.social-media {
			display: inline-block;
			.social {
				width: 40px;
				height: 40px;
				border: 1px solid $browncolor;
				float: left;
				margin-right: 15px;
				&:last-child {
					margin-right: 0;
				}
				&:hover {
					background-color: $browncolor;
					i {
						color: #fff;
					}
				}
				i {
					line-height: 42px;
					font-size: 20px;
					color: $browncolor;
				}
			}
		}
	}
	@media(max-width: 768px) {
		h3.title {
			margin-top: 30px;
		}
		.footer-copyright {
			margin-top: 0;
			.logo {
				margin-bottom: 30px;
			}
			.copyright {
				margin-bottom: 30px;
			}
		}
	}
}
