// Fonts
$mainfont 	: 'Volkhov', serif;
$bodyfont	: 'Karla', sans-serif;

// FONT COLORS
$browncolor	: #ceab50;

// BACKROUND COLORS


// FONT SIZES
$titlesize	: 35px;
$textsize	: 15px;


// Media Queries
$mediaRe : 320px;
$mediaXs : 480px;
$mediaSm : 768px;
$mediaMd : 960px;
$mediaLa : 1200px;

 