/* 
// TABLE OF CONTENTS //
body
	**** HEADER ****
	header - Header of page
		.info-bar - The bar in top that contains informations like location & phone number
			.info - Each information is inside this class
		.navbar - Main navbar/menu
			a.logo - Logo of the website
			ul#menu.main-menu - The main menu of the website
				li a - Menu item
	**** SLIDER ****
	.slider - Main slider using the flexslider structure
		.flexslider
			..
			flexslider structure
			..
	**** ALL SECTION HAVE THE SAME STRUCTURE ****
	**** OUR STORY SECTION ****
	.our-story - Container of section
		.section-text - Section title & description
			h2.section-title
			p.body-text - All the body text that has the same styles uses this class
	**** RESERVATION SECTION ****
	.reservation-section - Container of section
	**** SPECIALS SECTION ****
	.specials-section - Container of section
		- Structure used to add hover to images
		.img-wrap
			img
			.img-hover - Add hover color here and style
				.caption-text - The description that you will show or hide on hover
	**** MENU SECTION ****
	.menu-section - Container of section
		.title - Title of each categorie
		.meal-info - Every meal has its own informations and this is the class used to contain that
	**** TESTIMONIALS SECTION ****
	.testimonials-section - Container of section
		.flexslider2 - Using flexslider for testimonials (The same structure for every flexslider)
			p.testimonial-txt - Text
			p.client - Client name class
	**** GALLERY SECTION ****
	.gallery-section - Container of section
		span.icon - Icon in the title
	**** OUR CLIENTS SECTION ****
	.clients-section - Container of section
	**** CONTACT US SECTION ****
	.contact-section - Container of section
	**** FOOTER ****
		.footer-copyright - Copyright container class
*/

/* OVERALL */
a {
	text-decoration: none;
}

img {
	vertical-align: middle;
}

body {
	font-family: $bodyfont;
}

.section-title {
	font-family: $mainfont;
	font-size: $titlesize;
	text-align: center;
	margin-bottom: 15px;
	text-transform: uppercase;
	letter-spacing: 2px;
	@media(max-width: 480px) {
		font-size: 26px;
	}
}

.body-text {
	font-family: $bodyfont;
	font-size: $textsize;
}
