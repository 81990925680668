/* OVERALL */
*, *:after, *:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

/* GRID */
.row {
	margin-right: -15px;
	margin-left: -15px;
}

.row:after {
	content: "";
	display: table;
	clear: both;
}

[class*='col-'] {
	float: left;
}

.clearfix:after {
	content: "";
	display: table;
	clear: both;
}

.container {
	max-width: 1200px;
	margin: 0 auto;
	padding-left: 15px;
	padding-right: 15px;
}

.col-1{
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-1-2 {
	width: 50%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-1-3 {
	width: 33.3333333333%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-2-3 {
	width: 66.6666666667%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-1-4 {
	width: 25%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-3-4 {
	width: 75%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-1-5 {
	width: 20%;
	padding-left: 15px;
	padding-right: 15px;
}

.col-1-6 {
	width: 16.6666666667%;
	padding-left: 15px;
	padding-right: 15px;
}

/* SPECIAL COL-1-3 & GROUP */
.col-1--3 {
	width: 33.3333333333%;
	padding-left: 5px;
	padding-right: 5px;
}

.group {
	padding-left: 10px;
	padding-right: 10px;
}

@media (max-width: 768px) {
	.col-1--3 {
		width: 100%;
	}
}

/* MEDIA */
@media (max-width: 768px) {
	[class*='col-'] {
		width: 100%;
		// text-align: center;
	}
}

